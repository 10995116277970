import * as Types from '@amzn/gtpc-hamilton-disclosure-schema';

import type { ExecutionResult } from 'graphql';
export type CreateDataRequestsMutationVariables = Types.Exact<{
  data: Types.CreateDataRequestsInput;
}>;


export type CreateDataRequestsMutation = { createDataRequests?: Types.Maybe<Array<Pick<Types.EntityResponse, 'id'>>> };

export type CancelDataRequestsMutationVariables = Types.Exact<{
  data: Types.CancelDataRequestsInput;
}>;


export type CancelDataRequestsMutation = { cancelDataRequests?: Types.Maybe<Array<Pick<Types.EntityResponse, 'id'>>> };

export type DeclineDataRequestsMutationVariables = Types.Exact<{
  data: Types.DeclineDataRequestsInput;
}>;


export type DeclineDataRequestsMutation = { declineDataRequests?: Types.Maybe<Array<Pick<Types.EntityResponse, 'id'>>> };

export type SubmitDataRequestsMutationVariables = Types.Exact<{
  data: Types.SubmitDataRequestsInput;
}>;


export type SubmitDataRequestsMutation = { submitDataRequests?: Types.Maybe<Array<Pick<Types.EntityResponse, 'id'>>> };

export type NudgeDataRequestMutationVariables = Types.Exact<{
  data: Types.NudgeDataRequestInput;
}>;


export type NudgeDataRequestMutation = { nudgeDataRequest?: Types.Maybe<Array<Pick<Types.EntityResponse, 'id'>>> };

export type UpdateDataResponsesMutationVariables = Types.Exact<{
  data: Types.UpdateDataResponsesInput;
}>;


export type UpdateDataResponsesMutation = { updateDataResponses?: Types.Maybe<Array<Pick<Types.EntityResponse, 'id'>>> };

export type CreateZipExportMutationVariables = Types.Exact<{
  data: Types.CreateZipExportInput;
}>;


export type CreateZipExportMutation = { createZipExport?: Types.Maybe<Pick<Types.EntityResponse, 'id'>> };

export type SetupSandboxMutationVariables = Types.Exact<{
  sandboxType?: Types.InputMaybe<Types.SandboxType>;
}>;


export type SetupSandboxMutation = Pick<Types.Mutation, 'setupSandbox'>;

export type ApproveDataRequestsMutationVariables = Types.Exact<{
  data: Types.ApproveDataRequestsInput;
}>;


export type ApproveDataRequestsMutation = { approveDataRequests?: Types.Maybe<Array<Pick<Types.EntityResponse, 'id'>>> };

export type RejectDataRequestsMutationVariables = Types.Exact<{
  data: Types.RejectDataRequestsInput;
}>;


export type RejectDataRequestsMutation = { rejectDataRequests?: Types.Maybe<Array<Pick<Types.EntityResponse, 'id'>>> };

export type TemplateTextViewFieldFragment = Pick<Types.TemplateTextViewField, 'type' | 'fieldId' | 'primaryText' | 'secondaryText'>;

export type TemplateArtifactViewFieldFragment = Pick<Types.TemplateArtifactViewField, 'type' | 'fieldId' | 'displayedName'>;

export type TemplateListItemFragment = Pick<Types.TemplateListItem, 'value' | 'description'>;

export type TemplateListItemsFragment = { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> };

export type TemplateListTypeFragment = Pick<Types.TemplateListType, 'itemType'>;

type TemplateListItemType_TemplateListItems_Fragment = { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> };

type TemplateListItemType_TemplateListType_Fragment = Pick<Types.TemplateListType, 'itemType'>;

export type TemplateListItemTypeFragment = TemplateListItemType_TemplateListItems_Fragment | TemplateListItemType_TemplateListType_Fragment;

export type TemplateRadioInputFieldFragment = (
  Pick<Types.TemplateRadioInputField, 'type' | 'fieldId' | 'title' | 'optional' | 'fieldValidation'>
  & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
);

export type TemplateSelectInputFieldFragment = (
  Pick<Types.TemplateSelectInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
  & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
);

export type TemplateMultipleSelectInputFieldFragment = (
  Pick<Types.TemplateMultipleSelectInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
  & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
);

export type TemplateDateInputFieldFragment = (
  Pick<Types.TemplateDateInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
  & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
);

export type TemplateNumberInputFieldFragment = (
  Pick<Types.TemplateNumberInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
  & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
);

export type TemplateDocumentUploadInputFieldFragment = (
  Pick<Types.TemplateDocumentUploadInputField, 'type' | 'fieldId' | 'title' | 'hint' | 'optional' | 'fieldValidation'>
  & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
);

export type TemplateCheckboxInputFieldFragment = (
  Pick<Types.TemplateCheckboxInputField, 'type' | 'fieldId' | 'title' | 'optional' | 'fieldValidation'>
  & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
);

export type TemplateTextInputFieldFragment = (
  Pick<Types.TemplateTextInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'maxLength' | 'optional' | 'fieldValidation' | 'rows'>
  & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
);

export type VariableTextInputFieldFragment = Pick<Types.VariableTextInputField, 'type' | 'title' | 'inDetails' | 'inExport'>;

export type VariableDocumentUploadInputFieldFragment = Pick<Types.VariableDocumentUploadInputField, 'type' | 'title' | 'inDetails' | 'inExport'>;

type VariableTemplateField_VariableDocumentUploadInputField_Fragment = Pick<Types.VariableDocumentUploadInputField, 'type' | 'title' | 'inDetails' | 'inExport'>;

type VariableTemplateField_VariableTextInputField_Fragment = Pick<Types.VariableTextInputField, 'type' | 'title' | 'inDetails' | 'inExport'>;

export type VariableTemplateFieldFragment = VariableTemplateField_VariableDocumentUploadInputField_Fragment | VariableTemplateField_VariableTextInputField_Fragment;

type TemplateField_TemplateArtifactViewField_Fragment = Pick<Types.TemplateArtifactViewField, 'type' | 'fieldId' | 'displayedName'>;

type TemplateField_TemplateCheckboxInputField_Fragment = (
  Pick<Types.TemplateCheckboxInputField, 'type' | 'fieldId' | 'title' | 'optional' | 'fieldValidation'>
  & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
);

type TemplateField_TemplateDateInputField_Fragment = (
  Pick<Types.TemplateDateInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
  & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
);

type TemplateField_TemplateDocumentUploadInputField_Fragment = (
  Pick<Types.TemplateDocumentUploadInputField, 'type' | 'fieldId' | 'title' | 'hint' | 'optional' | 'fieldValidation'>
  & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
);

type TemplateField_TemplateMultipleSelectInputField_Fragment = (
  Pick<Types.TemplateMultipleSelectInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
  & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
);

type TemplateField_TemplateNumberInputField_Fragment = (
  Pick<Types.TemplateNumberInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
  & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
);

type TemplateField_TemplateRadioInputField_Fragment = (
  Pick<Types.TemplateRadioInputField, 'type' | 'fieldId' | 'title' | 'optional' | 'fieldValidation'>
  & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
);

type TemplateField_TemplateSelectInputField_Fragment = (
  Pick<Types.TemplateSelectInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
  & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
);

type TemplateField_TemplateTextInputField_Fragment = (
  Pick<Types.TemplateTextInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'maxLength' | 'optional' | 'fieldValidation' | 'rows'>
  & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
);

type TemplateField_TemplateTextViewField_Fragment = Pick<Types.TemplateTextViewField, 'type' | 'fieldId' | 'primaryText' | 'secondaryText'>;

export type TemplateFieldFragment = TemplateField_TemplateArtifactViewField_Fragment | TemplateField_TemplateCheckboxInputField_Fragment | TemplateField_TemplateDateInputField_Fragment | TemplateField_TemplateDocumentUploadInputField_Fragment | TemplateField_TemplateMultipleSelectInputField_Fragment | TemplateField_TemplateNumberInputField_Fragment | TemplateField_TemplateRadioInputField_Fragment | TemplateField_TemplateSelectInputField_Fragment | TemplateField_TemplateTextInputField_Fragment | TemplateField_TemplateTextViewField_Fragment;

export type TemplateSectionFragment = (
  Pick<Types.TemplateSection, 'title' | 'expandable'>
  & { fields: Array<Pick<Types.TemplateArtifactViewField, 'type' | 'fieldId' | 'displayedName'> | (
    Pick<Types.TemplateCheckboxInputField, 'type' | 'fieldId' | 'title' | 'optional' | 'fieldValidation'>
    & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
  ) | (
    Pick<Types.TemplateDateInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
    & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
  ) | (
    Pick<Types.TemplateDocumentUploadInputField, 'type' | 'fieldId' | 'title' | 'hint' | 'optional' | 'fieldValidation'>
    & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
  ) | (
    Pick<Types.TemplateMultipleSelectInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
    & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
  ) | (
    Pick<Types.TemplateNumberInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
    & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
  ) | (
    Pick<Types.TemplateRadioInputField, 'type' | 'fieldId' | 'title' | 'optional' | 'fieldValidation'>
    & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
  ) | (
    Pick<Types.TemplateSelectInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
    & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
  ) | (
    Pick<Types.TemplateTextInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'maxLength' | 'optional' | 'fieldValidation' | 'rows'>
    & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
  ) | Pick<Types.TemplateTextViewField, 'type' | 'fieldId' | 'primaryText' | 'secondaryText'>> }
);

export type DataRequestProviderSimplifiedFragment = Pick<Types.DataRequestProviderDetails, '_id' | 'name' | 'target_team_id' | 'requester_team_id' | 'completedDate' | 'lastNudgeDate' | 'status' | 'due_date' | 'creation_date' | 'template_id' | 'templateName' | 'provider_team_name' | 'requested_by_team_name' | 'completed_by_user_name' | 'completed_by_user' | 'isSquashable'>;

export type DataRequestProviderDetailsFragment = (
  Pick<Types.DataRequestProviderDetails, 'isSquashable' | 'notes' | '_id' | 'name' | 'target_team_id' | 'requester_team_id' | 'completedDate' | 'lastNudgeDate' | 'status' | 'due_date' | 'creation_date' | 'template_id' | 'templateName' | 'provider_team_name' | 'requested_by_team_name' | 'completed_by_user_name' | 'completed_by_user'>
  & { sections: Array<(
    Pick<Types.TemplateSection, 'title' | 'expandable'>
    & { fields: Array<Pick<Types.TemplateArtifactViewField, 'type' | 'fieldId' | 'displayedName'> | (
      Pick<Types.TemplateCheckboxInputField, 'type' | 'fieldId' | 'title' | 'optional' | 'fieldValidation'>
      & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
    ) | (
      Pick<Types.TemplateDateInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
      & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
    ) | (
      Pick<Types.TemplateDocumentUploadInputField, 'type' | 'fieldId' | 'title' | 'hint' | 'optional' | 'fieldValidation'>
      & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
    ) | (
      Pick<Types.TemplateMultipleSelectInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
      & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
    ) | (
      Pick<Types.TemplateNumberInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
      & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
    ) | (
      Pick<Types.TemplateRadioInputField, 'type' | 'fieldId' | 'title' | 'optional' | 'fieldValidation'>
      & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
    ) | (
      Pick<Types.TemplateSelectInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
      & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
    ) | (
      Pick<Types.TemplateTextInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'maxLength' | 'optional' | 'fieldValidation' | 'rows'>
      & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
    ) | Pick<Types.TemplateTextViewField, 'type' | 'fieldId' | 'primaryText' | 'secondaryText'>> }
  )>, data_response?: Types.Maybe<(
    Pick<Types.DataResponseDetails, '_id'>
    & { values: Array<(
      { __typename: 'FileResponseValue' }
      & Pick<Types.FileResponseValue, 'fieldId' | 'artifactId' | 'name' | 'size' | 'artifactStatus' | 'uploadDate'>
    ) | (
      { __typename: 'TextResponseValue' }
      & Pick<Types.TextResponseValue, 'fieldId' | 'value'>
    )> }
  )>, variables?: Types.Maybe<Array<Pick<Types.Variable, 'title' | 'value' | 'inDetails' | 'inExport'>>>, statusChangeReasons?: Types.Maybe<(
    { __typename: 'CancelledReasons' }
    & Pick<Types.CancelledReasons, 'cancelledReason' | 'cancelledReasonDetails'>
  ) | (
    { __typename: 'DeclinedReasons' }
    & Pick<Types.DeclinedReasons, 'declinedReason' | 'declinedReasonDetails'>
  ) | (
    { __typename: 'RejectedReasons' }
    & Pick<Types.RejectedReasons, 'rejectedReason' | 'rejectedReasonDetails'>
  )> }
);

export type DataRequestRequesterSimplifiedFragment = Pick<Types.DataRequestRequesterDetails, '_id' | 'name' | 'target_team_id' | 'requester_team_id' | 'completedDate' | 'lastNudgeDate' | 'status' | 'due_date' | 'creation_date' | 'template_id' | 'provider_team_name' | 'requested_by_team_name' | 'templateName' | 'requested_by_user_name' | 'requested_by_user'>;

export type DataRequestRequesterDetailsFragment = (
  Pick<Types.DataRequestRequesterDetails, 'notes' | 'isSquashable' | '_id' | 'name' | 'target_team_id' | 'requester_team_id' | 'completedDate' | 'lastNudgeDate' | 'status' | 'due_date' | 'creation_date' | 'template_id' | 'provider_team_name' | 'requested_by_team_name' | 'templateName' | 'requested_by_user_name' | 'requested_by_user'>
  & { sections: Array<(
    Pick<Types.TemplateSection, 'title' | 'expandable'>
    & { fields: Array<Pick<Types.TemplateArtifactViewField, 'type' | 'fieldId' | 'displayedName'> | (
      Pick<Types.TemplateCheckboxInputField, 'type' | 'fieldId' | 'title' | 'optional' | 'fieldValidation'>
      & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
    ) | (
      Pick<Types.TemplateDateInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
      & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
    ) | (
      Pick<Types.TemplateDocumentUploadInputField, 'type' | 'fieldId' | 'title' | 'hint' | 'optional' | 'fieldValidation'>
      & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
    ) | (
      Pick<Types.TemplateMultipleSelectInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
      & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
    ) | (
      Pick<Types.TemplateNumberInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
      & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
    ) | (
      Pick<Types.TemplateRadioInputField, 'type' | 'fieldId' | 'title' | 'optional' | 'fieldValidation'>
      & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
    ) | (
      Pick<Types.TemplateSelectInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
      & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
    ) | (
      Pick<Types.TemplateTextInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'maxLength' | 'optional' | 'fieldValidation' | 'rows'>
      & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
    ) | Pick<Types.TemplateTextViewField, 'type' | 'fieldId' | 'primaryText' | 'secondaryText'>> }
  )>, data_response?: Types.Maybe<(
    Pick<Types.DataResponseDetails, '_id'>
    & { values: Array<(
      { __typename: 'FileResponseValue' }
      & Pick<Types.FileResponseValue, 'fieldId' | 'artifactId' | 'name' | 'size' | 'artifactStatus' | 'uploadDate'>
    ) | (
      { __typename: 'TextResponseValue' }
      & Pick<Types.TextResponseValue, 'fieldId' | 'value'>
    )> }
  )>, statusChangeReasons?: Types.Maybe<(
    { __typename: 'CancelledReasons' }
    & Pick<Types.CancelledReasons, 'cancelledReason' | 'cancelledReasonDetails'>
  ) | (
    { __typename: 'DeclinedReasons' }
    & Pick<Types.DeclinedReasons, 'declinedReason' | 'declinedReasonDetails'>
  ) | (
    { __typename: 'RejectedReasons' }
    & Pick<Types.RejectedReasons, 'rejectedReason' | 'rejectedReasonDetails'>
  )>, variables?: Types.Maybe<Array<Pick<Types.Variable, 'title' | 'value' | 'inDetails' | 'inExport'>>> }
);

export type GetDataRequestQueryVariables = Types.Exact<{
  dataRequestId: Types.Scalars['String'];
}>;


export type GetDataRequestQuery = { getDataRequest?: Types.Maybe<(
    { __typename: 'DataRequestProviderDetails' }
    & Pick<Types.DataRequestProviderDetails, 'isSquashable' | 'notes' | '_id' | 'name' | 'target_team_id' | 'requester_team_id' | 'completedDate' | 'lastNudgeDate' | 'status' | 'due_date' | 'creation_date' | 'template_id' | 'templateName' | 'provider_team_name' | 'requested_by_team_name' | 'completed_by_user_name' | 'completed_by_user'>
    & { sections: Array<(
      Pick<Types.TemplateSection, 'title' | 'expandable'>
      & { fields: Array<Pick<Types.TemplateArtifactViewField, 'type' | 'fieldId' | 'displayedName'> | (
        Pick<Types.TemplateCheckboxInputField, 'type' | 'fieldId' | 'title' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
      ) | (
        Pick<Types.TemplateDateInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
      ) | (
        Pick<Types.TemplateDocumentUploadInputField, 'type' | 'fieldId' | 'title' | 'hint' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
      ) | (
        Pick<Types.TemplateMultipleSelectInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
      ) | (
        Pick<Types.TemplateNumberInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
      ) | (
        Pick<Types.TemplateRadioInputField, 'type' | 'fieldId' | 'title' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
      ) | (
        Pick<Types.TemplateSelectInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
      ) | (
        Pick<Types.TemplateTextInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'maxLength' | 'optional' | 'fieldValidation' | 'rows'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
      ) | Pick<Types.TemplateTextViewField, 'type' | 'fieldId' | 'primaryText' | 'secondaryText'>> }
    )>, data_response?: Types.Maybe<(
      Pick<Types.DataResponseDetails, '_id'>
      & { values: Array<(
        { __typename: 'FileResponseValue' }
        & Pick<Types.FileResponseValue, 'fieldId' | 'artifactId' | 'name' | 'size' | 'artifactStatus' | 'uploadDate'>
      ) | (
        { __typename: 'TextResponseValue' }
        & Pick<Types.TextResponseValue, 'fieldId' | 'value'>
      )> }
    )>, variables?: Types.Maybe<Array<Pick<Types.Variable, 'title' | 'value' | 'inDetails' | 'inExport'>>>, statusChangeReasons?: Types.Maybe<(
      { __typename: 'CancelledReasons' }
      & Pick<Types.CancelledReasons, 'cancelledReason' | 'cancelledReasonDetails'>
    ) | (
      { __typename: 'DeclinedReasons' }
      & Pick<Types.DeclinedReasons, 'declinedReason' | 'declinedReasonDetails'>
    ) | (
      { __typename: 'RejectedReasons' }
      & Pick<Types.RejectedReasons, 'rejectedReason' | 'rejectedReasonDetails'>
    )> }
  ) | (
    { __typename: 'DataRequestRequesterDetails' }
    & Pick<Types.DataRequestRequesterDetails, 'notes' | 'isSquashable' | '_id' | 'name' | 'target_team_id' | 'requester_team_id' | 'completedDate' | 'lastNudgeDate' | 'status' | 'due_date' | 'creation_date' | 'template_id' | 'provider_team_name' | 'requested_by_team_name' | 'templateName' | 'requested_by_user_name' | 'requested_by_user'>
    & { sections: Array<(
      Pick<Types.TemplateSection, 'title' | 'expandable'>
      & { fields: Array<Pick<Types.TemplateArtifactViewField, 'type' | 'fieldId' | 'displayedName'> | (
        Pick<Types.TemplateCheckboxInputField, 'type' | 'fieldId' | 'title' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
      ) | (
        Pick<Types.TemplateDateInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
      ) | (
        Pick<Types.TemplateDocumentUploadInputField, 'type' | 'fieldId' | 'title' | 'hint' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
      ) | (
        Pick<Types.TemplateMultipleSelectInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
      ) | (
        Pick<Types.TemplateNumberInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
      ) | (
        Pick<Types.TemplateRadioInputField, 'type' | 'fieldId' | 'title' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
      ) | (
        Pick<Types.TemplateSelectInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
      ) | (
        Pick<Types.TemplateTextInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'maxLength' | 'optional' | 'fieldValidation' | 'rows'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
      ) | Pick<Types.TemplateTextViewField, 'type' | 'fieldId' | 'primaryText' | 'secondaryText'>> }
    )>, data_response?: Types.Maybe<(
      Pick<Types.DataResponseDetails, '_id'>
      & { values: Array<(
        { __typename: 'FileResponseValue' }
        & Pick<Types.FileResponseValue, 'fieldId' | 'artifactId' | 'name' | 'size' | 'artifactStatus' | 'uploadDate'>
      ) | (
        { __typename: 'TextResponseValue' }
        & Pick<Types.TextResponseValue, 'fieldId' | 'value'>
      )> }
    )>, statusChangeReasons?: Types.Maybe<(
      { __typename: 'CancelledReasons' }
      & Pick<Types.CancelledReasons, 'cancelledReason' | 'cancelledReasonDetails'>
    ) | (
      { __typename: 'DeclinedReasons' }
      & Pick<Types.DeclinedReasons, 'declinedReason' | 'declinedReasonDetails'>
    ) | (
      { __typename: 'RejectedReasons' }
      & Pick<Types.RejectedReasons, 'rejectedReason' | 'rejectedReasonDetails'>
    )>, variables?: Types.Maybe<Array<Pick<Types.Variable, 'title' | 'value' | 'inDetails' | 'inExport'>>> }
  )> };

export type GenerateArtifactDownloadUrlQueryVariables = Types.Exact<{
  dataRequestId: Types.Scalars['String'];
  fieldId: Types.Scalars['String'];
  artifactId: Types.Scalars['String'];
}>;


export type GenerateArtifactDownloadUrlQuery = Pick<Types.Query, 'generateArtifactDownloadUrl'>;

export type GenerateTemplateArtifactDownloadUrlQueryVariables = Types.Exact<{
  dataRequestId: Types.Scalars['String'];
  fieldId: Types.Scalars['String'];
}>;


export type GenerateTemplateArtifactDownloadUrlQuery = Pick<Types.Query, 'generateTemplateArtifactDownloadUrl'>;

export type GenerateUploadUrlQueryVariables = Types.Exact<{
  dataRequestIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
  fieldId: Types.Scalars['String'];
  fileName: Types.Scalars['String'];
}>;


export type GenerateUploadUrlQuery = { generateUploadUrl?: Types.Maybe<Pick<Types.GetUploadUrlResponse, 'signedUrl' | 'artifactId' | 'fieldsJSON'>> };

export type GetTemplateQueryVariables = Types.Exact<{
  templateId: Types.Scalars['String'];
}>;


export type GetTemplateQuery = { getTemplate?: Types.Maybe<(
    Pick<Types.Template, '_id' | 'description' | 'name'>
    & { sections: Array<(
      Pick<Types.TemplateSection, 'title' | 'expandable'>
      & { fields: Array<Pick<Types.TemplateArtifactViewField, 'type' | 'fieldId' | 'displayedName'> | (
        Pick<Types.TemplateCheckboxInputField, 'type' | 'fieldId' | 'title' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
      ) | (
        Pick<Types.TemplateDateInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
      ) | (
        Pick<Types.TemplateDocumentUploadInputField, 'type' | 'fieldId' | 'title' | 'hint' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
      ) | (
        Pick<Types.TemplateMultipleSelectInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
      ) | (
        Pick<Types.TemplateNumberInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
      ) | (
        Pick<Types.TemplateRadioInputField, 'type' | 'fieldId' | 'title' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
      ) | (
        Pick<Types.TemplateSelectInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'optional' | 'fieldValidation'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>>, items: { value: Array<Pick<Types.TemplateListItem, 'value' | 'description'>> } | Pick<Types.TemplateListType, 'itemType'> }
      ) | (
        Pick<Types.TemplateTextInputField, 'type' | 'fieldId' | 'title' | 'placeholder' | 'maxLength' | 'optional' | 'fieldValidation' | 'rows'>
        & { description?: Types.Maybe<Pick<Types.TextValue, 'text' | 'a11yText'>> }
      ) | Pick<Types.TemplateTextViewField, 'type' | 'fieldId' | 'primaryText' | 'secondaryText'>> }
    )>, variables: Array<Types.Maybe<Pick<Types.VariableDocumentUploadInputField, 'type' | 'title' | 'inDetails' | 'inExport'> | Pick<Types.VariableTextInputField, 'type' | 'title' | 'inDetails' | 'inExport'>>> }
  )> };

export type ListTemplatesQueryVariables = Types.Exact<{
  teamNamespace: Types.Scalars['String'];
}>;


export type ListTemplatesQuery = { listTemplates?: Types.Maybe<Array<Pick<Types.Template, '_id' | 'description' | 'name'>>> };

export type ListRequesterDataRequestsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListRequesterDataRequestsQuery = { listRequesterDataRequests?: Types.Maybe<Array<Pick<Types.DataRequestRequesterDetails, '_id' | 'name' | 'target_team_id' | 'requester_team_id' | 'completedDate' | 'lastNudgeDate' | 'status' | 'due_date' | 'creation_date' | 'template_id' | 'provider_team_name' | 'requested_by_team_name' | 'templateName' | 'requested_by_user_name' | 'requested_by_user'>>> };

export type ListProviderDataRequestsQueryVariables = Types.Exact<{
  dataRequestIds?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
}>;


export type ListProviderDataRequestsQuery = { listProviderDataRequests?: Types.Maybe<Array<Pick<Types.DataRequestProviderDetails, '_id' | 'name' | 'target_team_id' | 'requester_team_id' | 'completedDate' | 'lastNudgeDate' | 'status' | 'due_date' | 'creation_date' | 'template_id' | 'templateName' | 'provider_team_name' | 'requested_by_team_name' | 'completed_by_user_name' | 'completed_by_user' | 'isSquashable'>>> };

export type DeleteArtifactQueryVariables = Types.Exact<{
  dataRequestIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
  fieldId: Types.Scalars['String'];
  artifactId: Types.Scalars['String'];
}>;


export type DeleteArtifactQuery = Pick<Types.Query, 'deleteArtifact'>;

export type GetZipExportQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetZipExportQuery = { getZipExport?: Types.Maybe<Pick<Types.ExportDetails, 'id' | 'status' | 'url' | 'requestedAt' | 'dataRequestIds'>> };

export type ListZipExportsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListZipExportsQuery = { listZipExports?: Types.Maybe<Array<Pick<Types.ExportDetails, 'id' | 'status' | 'url' | 'requestedAt' | 'dataRequestIds'>>> };

export type GetFeatureFlagQueryVariables = Types.Exact<{
  feature: Types.Scalars['String'];
  entityId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetFeatureFlagQuery = { getFeatureFlag?: Types.Maybe<Pick<Types.FeatureFlag, 'value' | 'feature'>> };

export type DataRequestProviderDetailsBulkResponseFragment = (
  Pick<Types.DataRequestProviderDetails, '_id' | 'name' | 'creation_date' | 'due_date' | 'status' | 'requester_team_id'>
  & { variables?: Types.Maybe<Array<Pick<Types.Variable, 'title' | 'value'>>>, data_response?: Types.Maybe<{ values: Array<(
      { __typename: 'FileResponseValue' }
      & Pick<Types.FileResponseValue, 'fieldId' | 'artifactId' | 'name' | 'size' | 'artifactStatus' | 'uploadDate'>
    ) | (
      { __typename: 'TextResponseValue' }
      & Pick<Types.TextResponseValue, 'fieldId' | 'value'>
    )> }> }
);

export type GetProviderDataRequestsForBulkResponseQueryVariables = Types.Exact<{
  dataRequestIds?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
}>;


export type GetProviderDataRequestsForBulkResponseQuery = { listProviderDataRequests?: Types.Maybe<Array<(
    Pick<Types.DataRequestProviderDetails, '_id' | 'name' | 'creation_date' | 'due_date' | 'status' | 'requester_team_id'>
    & { variables?: Types.Maybe<Array<Pick<Types.Variable, 'title' | 'value'>>>, data_response?: Types.Maybe<{ values: Array<(
        { __typename: 'FileResponseValue' }
        & Pick<Types.FileResponseValue, 'fieldId' | 'artifactId' | 'name' | 'size' | 'artifactStatus' | 'uploadDate'>
      ) | (
        { __typename: 'TextResponseValue' }
        & Pick<Types.TextResponseValue, 'fieldId' | 'value'>
      )> }> }
  )>> };

export const VariableDocumentUploadInputFieldFragmentDoc = `
    fragment VariableDocumentUploadInputField on VariableDocumentUploadInputField {
  type
  title
  inDetails
  inExport
}
    `;
export const VariableTextInputFieldFragmentDoc = `
    fragment VariableTextInputField on VariableTextInputField {
  type
  title
  inDetails
  inExport
}
    `;
export const VariableTemplateFieldFragmentDoc = `
    fragment VariableTemplateField on VariableTemplateField {
  ... on VariableDocumentUploadInputField {
    ...VariableDocumentUploadInputField
  }
  ... on VariableTextInputField {
    ...VariableTextInputField
  }
}
    `;
export const DataRequestProviderSimplifiedFragmentDoc = `
    fragment DataRequestProviderSimplified on DataRequestProviderDetails {
  _id
  name
  target_team_id
  requester_team_id
  completedDate
  lastNudgeDate
  status
  due_date
  creation_date
  template_id
  templateName
  provider_team_name
  requested_by_team_name
  completed_by_user_name
  completed_by_user
  isSquashable
}
    `;
export const TemplateTextViewFieldFragmentDoc = `
    fragment TemplateTextViewField on TemplateTextViewField {
  type
  fieldId
  primaryText
  secondaryText
}
    `;
export const TemplateArtifactViewFieldFragmentDoc = `
    fragment TemplateArtifactViewField on TemplateArtifactViewField {
  type
  fieldId
  displayedName
}
    `;
export const TemplateListItemFragmentDoc = `
    fragment TemplateListItem on TemplateListItem {
  value
  description
}
    `;
export const TemplateListItemsFragmentDoc = `
    fragment TemplateListItems on TemplateListItems {
  value {
    ...TemplateListItem
  }
}
    `;
export const TemplateListTypeFragmentDoc = `
    fragment TemplateListType on TemplateListType {
  itemType
}
    `;
export const TemplateListItemTypeFragmentDoc = `
    fragment TemplateListItemType on TemplateListItemType {
  ... on TemplateListItems {
    ...TemplateListItems
  }
  ... on TemplateListType {
    ...TemplateListType
  }
}
    `;
export const TemplateRadioInputFieldFragmentDoc = `
    fragment TemplateRadioInputField on TemplateRadioInputField {
  type
  fieldId
  title
  description {
    text
    a11yText
  }
  items {
    ...TemplateListItemType
  }
  optional
  fieldValidation
}
    `;
export const TemplateSelectInputFieldFragmentDoc = `
    fragment TemplateSelectInputField on TemplateSelectInputField {
  type
  fieldId
  title
  description {
    text
    a11yText
  }
  placeholder
  items {
    ...TemplateListItemType
  }
  optional
  fieldValidation
}
    `;
export const TemplateMultipleSelectInputFieldFragmentDoc = `
    fragment TemplateMultipleSelectInputField on TemplateMultipleSelectInputField {
  type
  fieldId
  title
  description {
    text
    a11yText
  }
  placeholder
  items {
    ...TemplateListItemType
  }
  optional
  fieldValidation
}
    `;
export const TemplateDateInputFieldFragmentDoc = `
    fragment TemplateDateInputField on TemplateDateInputField {
  type
  fieldId
  title
  description {
    text
    a11yText
  }
  placeholder
  optional
  fieldValidation
}
    `;
export const TemplateDocumentUploadInputFieldFragmentDoc = `
    fragment TemplateDocumentUploadInputField on TemplateDocumentUploadInputField {
  type
  fieldId
  title
  description {
    text
    a11yText
  }
  hint
  optional
  fieldValidation
}
    `;
export const TemplateCheckboxInputFieldFragmentDoc = `
    fragment TemplateCheckboxInputField on TemplateCheckboxInputField {
  type
  fieldId
  title
  description {
    text
    a11yText
  }
  optional
  fieldValidation
}
    `;
export const TemplateNumberInputFieldFragmentDoc = `
    fragment TemplateNumberInputField on TemplateNumberInputField {
  type
  fieldId
  title
  description {
    text
    a11yText
  }
  placeholder
  optional
  fieldValidation
}
    `;
export const TemplateTextInputFieldFragmentDoc = `
    fragment TemplateTextInputField on TemplateTextInputField {
  type
  fieldId
  title
  placeholder
  description {
    text
    a11yText
  }
  maxLength
  optional
  fieldValidation
  rows
}
    `;
export const TemplateFieldFragmentDoc = `
    fragment TemplateField on TemplateField {
  ... on TemplateTextViewField {
    ...TemplateTextViewField
  }
  ... on TemplateArtifactViewField {
    ...TemplateArtifactViewField
  }
  ... on TemplateRadioInputField {
    ...TemplateRadioInputField
  }
  ... on TemplateSelectInputField {
    ...TemplateSelectInputField
  }
  ... on TemplateMultipleSelectInputField {
    ...TemplateMultipleSelectInputField
  }
  ... on TemplateDateInputField {
    ...TemplateDateInputField
  }
  ... on TemplateDocumentUploadInputField {
    ...TemplateDocumentUploadInputField
  }
  ... on TemplateCheckboxInputField {
    ...TemplateCheckboxInputField
  }
  ... on TemplateNumberInputField {
    ...TemplateNumberInputField
  }
  ... on TemplateTextInputField {
    ...TemplateTextInputField
  }
}
    `;
export const TemplateSectionFragmentDoc = `
    fragment TemplateSection on TemplateSection {
  title
  fields {
    ...TemplateField
  }
  expandable
}
    `;
export const DataRequestProviderDetailsFragmentDoc = `
    fragment DataRequestProviderDetails on DataRequestProviderDetails {
  ...DataRequestProviderSimplified
  sections {
    ...TemplateSection
  }
  data_response {
    _id
    values {
      __typename
      ... on TextResponseValue {
        fieldId
        value
      }
      ... on FileResponseValue {
        fieldId
        artifactId
        name
        size
        artifactStatus
        uploadDate
      }
    }
  }
  isSquashable
  notes
  variables {
    title
    value
    inDetails
    inExport
  }
  statusChangeReasons {
    __typename
    ... on DeclinedReasons {
      declinedReason
      declinedReasonDetails
    }
    ... on CancelledReasons {
      cancelledReason
      cancelledReasonDetails
    }
    ... on RejectedReasons {
      rejectedReason
      rejectedReasonDetails
    }
  }
}
    `;
export const DataRequestRequesterSimplifiedFragmentDoc = `
    fragment DataRequestRequesterSimplified on DataRequestRequesterDetails {
  _id
  name
  target_team_id
  requester_team_id
  completedDate
  lastNudgeDate
  status
  due_date
  creation_date
  template_id
  provider_team_name
  requested_by_team_name
  templateName
  requested_by_user_name
  requested_by_user
}
    `;
export const DataRequestRequesterDetailsFragmentDoc = `
    fragment DataRequestRequesterDetails on DataRequestRequesterDetails {
  ...DataRequestRequesterSimplified
  sections {
    title
    fields {
      ...TemplateField
    }
    expandable
  }
  data_response {
    _id
    values {
      __typename
      ... on TextResponseValue {
        fieldId
        value
      }
      ... on FileResponseValue {
        fieldId
        artifactId
        name
        size
        artifactStatus
        uploadDate
      }
    }
  }
  notes
  statusChangeReasons {
    __typename
    ... on DeclinedReasons {
      declinedReason
      declinedReasonDetails
    }
    ... on CancelledReasons {
      cancelledReason
      cancelledReasonDetails
    }
    ... on RejectedReasons {
      rejectedReason
      rejectedReasonDetails
    }
  }
  isSquashable
  variables {
    title
    value
    inDetails
    inExport
  }
}
    `;
export const DataRequestProviderDetailsBulkResponseFragmentDoc = `
    fragment DataRequestProviderDetailsBulkResponse on DataRequestProviderDetails {
  _id
  name
  creation_date
  due_date
  status
  requester_team_id
  variables {
    title
    value
  }
  data_response {
    values {
      __typename
      ... on TextResponseValue {
        fieldId
        value
      }
      ... on FileResponseValue {
        fieldId
        artifactId
        name
        size
        artifactStatus
        uploadDate
      }
    }
  }
}
    `;
export const CreateDataRequestsDocument = `
    mutation createDataRequests($data: CreateDataRequestsInput!) {
  createDataRequests(data: $data) {
    id
  }
}
    `;
export const CancelDataRequestsDocument = `
    mutation cancelDataRequests($data: CancelDataRequestsInput!) {
  cancelDataRequests(data: $data) {
    id
  }
}
    `;
export const DeclineDataRequestsDocument = `
    mutation declineDataRequests($data: DeclineDataRequestsInput!) {
  declineDataRequests(data: $data) {
    id
  }
}
    `;
export const SubmitDataRequestsDocument = `
    mutation submitDataRequests($data: SubmitDataRequestsInput!) {
  submitDataRequests(data: $data) {
    id
  }
}
    `;
export const NudgeDataRequestDocument = `
    mutation nudgeDataRequest($data: NudgeDataRequestInput!) {
  nudgeDataRequest(data: $data) {
    id
  }
}
    `;
export const UpdateDataResponsesDocument = `
    mutation updateDataResponses($data: UpdateDataResponsesInput!) {
  updateDataResponses(data: $data) {
    id
  }
}
    `;
export const CreateZipExportDocument = `
    mutation createZipExport($data: CreateZipExportInput!) {
  createZipExport(data: $data) {
    id
  }
}
    `;
export const SetupSandboxDocument = `
    mutation setupSandbox($sandboxType: SandboxType) {
  setupSandbox(sandboxType: $sandboxType)
}
    `;
export const ApproveDataRequestsDocument = `
    mutation approveDataRequests($data: ApproveDataRequestsInput!) {
  approveDataRequests(data: $data) {
    id
  }
}
    `;
export const RejectDataRequestsDocument = `
    mutation rejectDataRequests($data: RejectDataRequestsInput!) {
  rejectDataRequests(data: $data) {
    id
  }
}
    `;
export const GetDataRequestDocument = `
    query getDataRequest($dataRequestId: String!) {
  getDataRequest(dataRequestId: $dataRequestId) {
    __typename
    ... on DataRequestProviderDetails {
      ...DataRequestProviderDetails
    }
    ... on DataRequestRequesterDetails {
      ...DataRequestRequesterDetails
    }
  }
}
    ${DataRequestProviderDetailsFragmentDoc}
${DataRequestProviderSimplifiedFragmentDoc}
${TemplateSectionFragmentDoc}
${TemplateFieldFragmentDoc}
${TemplateTextViewFieldFragmentDoc}
${TemplateArtifactViewFieldFragmentDoc}
${TemplateRadioInputFieldFragmentDoc}
${TemplateListItemTypeFragmentDoc}
${TemplateListItemsFragmentDoc}
${TemplateListItemFragmentDoc}
${TemplateListTypeFragmentDoc}
${TemplateSelectInputFieldFragmentDoc}
${TemplateMultipleSelectInputFieldFragmentDoc}
${TemplateDateInputFieldFragmentDoc}
${TemplateDocumentUploadInputFieldFragmentDoc}
${TemplateCheckboxInputFieldFragmentDoc}
${TemplateNumberInputFieldFragmentDoc}
${TemplateTextInputFieldFragmentDoc}
${DataRequestRequesterDetailsFragmentDoc}
${DataRequestRequesterSimplifiedFragmentDoc}`;
export const GenerateArtifactDownloadUrlDocument = `
    query generateArtifactDownloadUrl($dataRequestId: String!, $fieldId: String!, $artifactId: String!) {
  generateArtifactDownloadUrl(
    dataRequestId: $dataRequestId
    fieldId: $fieldId
    artifactId: $artifactId
  )
}
    `;
export const GenerateTemplateArtifactDownloadUrlDocument = `
    query generateTemplateArtifactDownloadUrl($dataRequestId: String!, $fieldId: String!) {
  generateTemplateArtifactDownloadUrl(
    dataRequestId: $dataRequestId
    fieldId: $fieldId
  )
}
    `;
export const GenerateUploadUrlDocument = `
    query generateUploadUrl($dataRequestIds: [String!]!, $fieldId: String!, $fileName: String!) {
  generateUploadUrl(
    dataRequestIds: $dataRequestIds
    fieldId: $fieldId
    fileName: $fileName
  ) {
    signedUrl
    artifactId
    fieldsJSON
  }
}
    `;
export const GetTemplateDocument = `
    query getTemplate($templateId: String!) {
  getTemplate(templateId: $templateId) {
    _id
    description
    name
    sections {
      title
      fields {
        ...TemplateField
      }
      expandable
    }
    variables {
      ...VariableTemplateField
    }
  }
}
    ${TemplateFieldFragmentDoc}
${TemplateTextViewFieldFragmentDoc}
${TemplateArtifactViewFieldFragmentDoc}
${TemplateRadioInputFieldFragmentDoc}
${TemplateListItemTypeFragmentDoc}
${TemplateListItemsFragmentDoc}
${TemplateListItemFragmentDoc}
${TemplateListTypeFragmentDoc}
${TemplateSelectInputFieldFragmentDoc}
${TemplateMultipleSelectInputFieldFragmentDoc}
${TemplateDateInputFieldFragmentDoc}
${TemplateDocumentUploadInputFieldFragmentDoc}
${TemplateCheckboxInputFieldFragmentDoc}
${TemplateNumberInputFieldFragmentDoc}
${TemplateTextInputFieldFragmentDoc}
${VariableTemplateFieldFragmentDoc}
${VariableDocumentUploadInputFieldFragmentDoc}
${VariableTextInputFieldFragmentDoc}`;
export const ListTemplatesDocument = `
    query listTemplates($teamNamespace: String!) {
  listTemplates(teamNamespace: $teamNamespace) {
    _id
    description
    name
  }
}
    `;
export const ListRequesterDataRequestsDocument = `
    query listRequesterDataRequests {
  listRequesterDataRequests {
    ...DataRequestRequesterSimplified
  }
}
    ${DataRequestRequesterSimplifiedFragmentDoc}`;
export const ListProviderDataRequestsDocument = `
    query listProviderDataRequests($dataRequestIds: [String!]) {
  listProviderDataRequests(dataRequestIds: $dataRequestIds) {
    ...DataRequestProviderSimplified
  }
}
    ${DataRequestProviderSimplifiedFragmentDoc}`;
export const DeleteArtifactDocument = `
    query deleteArtifact($dataRequestIds: [String!]!, $fieldId: String!, $artifactId: String!) {
  deleteArtifact(
    dataRequestIds: $dataRequestIds
    fieldId: $fieldId
    artifactId: $artifactId
  )
}
    `;
export const GetZipExportDocument = `
    query getZipExport($id: String!) {
  getZipExport(id: $id) {
    id
    status
    url
    requestedAt
    dataRequestIds
  }
}
    `;
export const ListZipExportsDocument = `
    query listZipExports {
  listZipExports {
    id
    status
    url
    requestedAt
    dataRequestIds
  }
}
    `;
export const GetFeatureFlagDocument = `
    query getFeatureFlag($feature: String!, $entityId: String) {
  getFeatureFlag(feature: $feature, entityId: $entityId) {
    value
    feature
  }
}
    `;
export const GetProviderDataRequestsForBulkResponseDocument = `
    query getProviderDataRequestsForBulkResponse($dataRequestIds: [String!]) {
  listProviderDataRequests(dataRequestIds: $dataRequestIds) {
    ...DataRequestProviderDetailsBulkResponse
  }
}
    ${DataRequestProviderDetailsBulkResponseFragmentDoc}`;
export type Requester<C = {}, E = unknown> = <R, V>(doc: string, vars?: V, options?: C) => Promise<ExecutionResult<R, E>> | AsyncIterable<ExecutionResult<R, E>>
export function getSdk<C, E>(requester: Requester<C, E>) {
  return {
    createDataRequests(variables: CreateDataRequestsMutationVariables, options?: C): Promise<ExecutionResult<CreateDataRequestsMutation, E>> {
      return requester<CreateDataRequestsMutation, CreateDataRequestsMutationVariables>(CreateDataRequestsDocument, variables, options) as Promise<ExecutionResult<CreateDataRequestsMutation, E>>;
    },
    cancelDataRequests(variables: CancelDataRequestsMutationVariables, options?: C): Promise<ExecutionResult<CancelDataRequestsMutation, E>> {
      return requester<CancelDataRequestsMutation, CancelDataRequestsMutationVariables>(CancelDataRequestsDocument, variables, options) as Promise<ExecutionResult<CancelDataRequestsMutation, E>>;
    },
    declineDataRequests(variables: DeclineDataRequestsMutationVariables, options?: C): Promise<ExecutionResult<DeclineDataRequestsMutation, E>> {
      return requester<DeclineDataRequestsMutation, DeclineDataRequestsMutationVariables>(DeclineDataRequestsDocument, variables, options) as Promise<ExecutionResult<DeclineDataRequestsMutation, E>>;
    },
    submitDataRequests(variables: SubmitDataRequestsMutationVariables, options?: C): Promise<ExecutionResult<SubmitDataRequestsMutation, E>> {
      return requester<SubmitDataRequestsMutation, SubmitDataRequestsMutationVariables>(SubmitDataRequestsDocument, variables, options) as Promise<ExecutionResult<SubmitDataRequestsMutation, E>>;
    },
    nudgeDataRequest(variables: NudgeDataRequestMutationVariables, options?: C): Promise<ExecutionResult<NudgeDataRequestMutation, E>> {
      return requester<NudgeDataRequestMutation, NudgeDataRequestMutationVariables>(NudgeDataRequestDocument, variables, options) as Promise<ExecutionResult<NudgeDataRequestMutation, E>>;
    },
    updateDataResponses(variables: UpdateDataResponsesMutationVariables, options?: C): Promise<ExecutionResult<UpdateDataResponsesMutation, E>> {
      return requester<UpdateDataResponsesMutation, UpdateDataResponsesMutationVariables>(UpdateDataResponsesDocument, variables, options) as Promise<ExecutionResult<UpdateDataResponsesMutation, E>>;
    },
    createZipExport(variables: CreateZipExportMutationVariables, options?: C): Promise<ExecutionResult<CreateZipExportMutation, E>> {
      return requester<CreateZipExportMutation, CreateZipExportMutationVariables>(CreateZipExportDocument, variables, options) as Promise<ExecutionResult<CreateZipExportMutation, E>>;
    },
    setupSandbox(variables?: SetupSandboxMutationVariables, options?: C): Promise<ExecutionResult<SetupSandboxMutation, E>> {
      return requester<SetupSandboxMutation, SetupSandboxMutationVariables>(SetupSandboxDocument, variables, options) as Promise<ExecutionResult<SetupSandboxMutation, E>>;
    },
    approveDataRequests(variables: ApproveDataRequestsMutationVariables, options?: C): Promise<ExecutionResult<ApproveDataRequestsMutation, E>> {
      return requester<ApproveDataRequestsMutation, ApproveDataRequestsMutationVariables>(ApproveDataRequestsDocument, variables, options) as Promise<ExecutionResult<ApproveDataRequestsMutation, E>>;
    },
    rejectDataRequests(variables: RejectDataRequestsMutationVariables, options?: C): Promise<ExecutionResult<RejectDataRequestsMutation, E>> {
      return requester<RejectDataRequestsMutation, RejectDataRequestsMutationVariables>(RejectDataRequestsDocument, variables, options) as Promise<ExecutionResult<RejectDataRequestsMutation, E>>;
    },
    getDataRequest(variables: GetDataRequestQueryVariables, options?: C): Promise<ExecutionResult<GetDataRequestQuery, E>> {
      return requester<GetDataRequestQuery, GetDataRequestQueryVariables>(GetDataRequestDocument, variables, options) as Promise<ExecutionResult<GetDataRequestQuery, E>>;
    },
    generateArtifactDownloadUrl(variables: GenerateArtifactDownloadUrlQueryVariables, options?: C): Promise<ExecutionResult<GenerateArtifactDownloadUrlQuery, E>> {
      return requester<GenerateArtifactDownloadUrlQuery, GenerateArtifactDownloadUrlQueryVariables>(GenerateArtifactDownloadUrlDocument, variables, options) as Promise<ExecutionResult<GenerateArtifactDownloadUrlQuery, E>>;
    },
    generateTemplateArtifactDownloadUrl(variables: GenerateTemplateArtifactDownloadUrlQueryVariables, options?: C): Promise<ExecutionResult<GenerateTemplateArtifactDownloadUrlQuery, E>> {
      return requester<GenerateTemplateArtifactDownloadUrlQuery, GenerateTemplateArtifactDownloadUrlQueryVariables>(GenerateTemplateArtifactDownloadUrlDocument, variables, options) as Promise<ExecutionResult<GenerateTemplateArtifactDownloadUrlQuery, E>>;
    },
    generateUploadUrl(variables: GenerateUploadUrlQueryVariables, options?: C): Promise<ExecutionResult<GenerateUploadUrlQuery, E>> {
      return requester<GenerateUploadUrlQuery, GenerateUploadUrlQueryVariables>(GenerateUploadUrlDocument, variables, options) as Promise<ExecutionResult<GenerateUploadUrlQuery, E>>;
    },
    getTemplate(variables: GetTemplateQueryVariables, options?: C): Promise<ExecutionResult<GetTemplateQuery, E>> {
      return requester<GetTemplateQuery, GetTemplateQueryVariables>(GetTemplateDocument, variables, options) as Promise<ExecutionResult<GetTemplateQuery, E>>;
    },
    listTemplates(variables: ListTemplatesQueryVariables, options?: C): Promise<ExecutionResult<ListTemplatesQuery, E>> {
      return requester<ListTemplatesQuery, ListTemplatesQueryVariables>(ListTemplatesDocument, variables, options) as Promise<ExecutionResult<ListTemplatesQuery, E>>;
    },
    listRequesterDataRequests(variables?: ListRequesterDataRequestsQueryVariables, options?: C): Promise<ExecutionResult<ListRequesterDataRequestsQuery, E>> {
      return requester<ListRequesterDataRequestsQuery, ListRequesterDataRequestsQueryVariables>(ListRequesterDataRequestsDocument, variables, options) as Promise<ExecutionResult<ListRequesterDataRequestsQuery, E>>;
    },
    listProviderDataRequests(variables?: ListProviderDataRequestsQueryVariables, options?: C): Promise<ExecutionResult<ListProviderDataRequestsQuery, E>> {
      return requester<ListProviderDataRequestsQuery, ListProviderDataRequestsQueryVariables>(ListProviderDataRequestsDocument, variables, options) as Promise<ExecutionResult<ListProviderDataRequestsQuery, E>>;
    },
    deleteArtifact(variables: DeleteArtifactQueryVariables, options?: C): Promise<ExecutionResult<DeleteArtifactQuery, E>> {
      return requester<DeleteArtifactQuery, DeleteArtifactQueryVariables>(DeleteArtifactDocument, variables, options) as Promise<ExecutionResult<DeleteArtifactQuery, E>>;
    },
    getZipExport(variables: GetZipExportQueryVariables, options?: C): Promise<ExecutionResult<GetZipExportQuery, E>> {
      return requester<GetZipExportQuery, GetZipExportQueryVariables>(GetZipExportDocument, variables, options) as Promise<ExecutionResult<GetZipExportQuery, E>>;
    },
    listZipExports(variables?: ListZipExportsQueryVariables, options?: C): Promise<ExecutionResult<ListZipExportsQuery, E>> {
      return requester<ListZipExportsQuery, ListZipExportsQueryVariables>(ListZipExportsDocument, variables, options) as Promise<ExecutionResult<ListZipExportsQuery, E>>;
    },
    getFeatureFlag(variables: GetFeatureFlagQueryVariables, options?: C): Promise<ExecutionResult<GetFeatureFlagQuery, E>> {
      return requester<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>(GetFeatureFlagDocument, variables, options) as Promise<ExecutionResult<GetFeatureFlagQuery, E>>;
    },
    getProviderDataRequestsForBulkResponse(variables?: GetProviderDataRequestsForBulkResponseQueryVariables, options?: C): Promise<ExecutionResult<GetProviderDataRequestsForBulkResponseQuery, E>> {
      return requester<GetProviderDataRequestsForBulkResponseQuery, GetProviderDataRequestsForBulkResponseQueryVariables>(GetProviderDataRequestsForBulkResponseDocument, variables, options) as Promise<ExecutionResult<GetProviderDataRequestsForBulkResponseQuery, E>>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;