import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";

void i18n
    .use(
        resourcesToBackend((language, namespace, callback) => {
            import(`./locales/${language}/${namespace}.json`)
                .then(resources => {
                    callback(null, resources);
                })
                .catch(error => {
                    callback(error, null);
                });
        }),
    )
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: process.env.NODE_ENV !== "production",
        fallbackLng: "en-GB",
        load: "currentOnly",
        ns: ["authenticate", "components", "sandbox", "help", "data-requests", "table"],
        defaultNS: "authenticate",
        interpolation: { escapeValue: false },
    });

export default i18n;
