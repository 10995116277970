import { NumericConstants } from "@amzn/gtpc-hamilton-commons";

export const SAVE_VALUES_TIMEOUT = 500;
// TODO Move this to shared constants package
// NOTE: Changing the following path will
// - break existing links to data requests
// - require updates to link generation in notifications and disclosure
export const DATA_REQUEST_PATH = "/data-requests";
// 500mb for now, might be or will be changed in HAMI-988
// make sure that it is synced with the same constant in disclosure
export const UPLOAD_MAX_FILE_SIZE = 500 * 1024 * 1024;
export const REASON_DETAILS_MAX_LENGTH = NumericConstants.SCHEMA_SHORT_TEXT_MAX_LENGTH;
export const MAX_MULTI_SELECT_ITEMS_ALLOWED = 150;
export const MAX_NUM_BULK_DATA_REQUESTS_TO_SHOW = 10;
