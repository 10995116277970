const getRequiredEnvironmentVariable = (variableName: string): string => {
    const value = process.env[variableName];

    if (!value) {
        let errorMessage = `Required environment variable not set: ${variableName}.`;
        if (process.env.NODE_ENV !== "production") {
            errorMessage +=
                " If you are pointing to your local stack please check you have generated envs/.env.dev.local using `bb setupLocalEnv`";
        }
        throw new Error(errorMessage);
    }

    return value;
};

// HAM
const COGNITO_IDENTITY_POOL_ID = getRequiredEnvironmentVariable("REACT_APP_COGNITO_IDENTITY_POOL_ID");
const COGNITO_USER_POOL_ID = getRequiredEnvironmentVariable("REACT_APP_COGNITO_USER_POOL_ID");
const COGNITO_USER_POOL_WEB_CLIENT_ID = getRequiredEnvironmentVariable("REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID");
const COGNITO_USER_POOL_DOMAIN = getRequiredEnvironmentVariable("REACT_APP_COGNITO_USER_POOL_DOMAIN");
const ACCOUNT_MANAGEMENT_APPSYNC_GRAPHQL_ENDPOINT = getRequiredEnvironmentVariable(
    "REACT_APP_ACCOUNT_MANAGEMENT_APPSYNC_GRAPHQL_ENDPOINT",
);
const ACCOUNT_MANAGEMENT_REGION = getRequiredEnvironmentVariable("REACT_APP_ACCOUNT_MANAGEMENT_REGION");

// Disclosure
const REGION = getRequiredEnvironmentVariable("REACT_APP_REGION");
const APPSYNC_GRAPHQL_ENDPOINT = getRequiredEnvironmentVariable("REACT_APP_APPSYNC_GRAPHQL_ENDPOINT");
const REDIRECT_URL = window.location.protocol + "//" + window.location.host + "/";

// RUM
const RUM_ENDPOINT = `https://dataplane.rum.${REGION}.amazonaws.com`;
const RUM_GUEST_ROLE_ARN = getRequiredEnvironmentVariable("REACT_APP_RUM_GUEST_ROLE_ARN");
const RUM_GUEST_IDENTITY_POOL_ID = getRequiredEnvironmentVariable("REACT_APP_RUM_GUEST_IDENTITY_POOL_ID");
const RUM_APP_ID = getRequiredEnvironmentVariable("REACT_APP_RUM_APP_ID");
const RUM_APP_VERSION = getRequiredEnvironmentVariable("REACT_APP_RUM_APP_VERSION");

export const cognitoUserConfig = {
    aws_project_region: ACCOUNT_MANAGEMENT_REGION,
    aws_cognito_identity_pool_id: COGNITO_IDENTITY_POOL_ID,
    aws_user_pools_id: COGNITO_USER_POOL_ID,
    aws_user_pools_web_client_id: COGNITO_USER_POOL_WEB_CLIENT_ID,
    Auth: {
        identityPoolId: COGNITO_IDENTITY_POOL_ID,
        region: ACCOUNT_MANAGEMENT_REGION,
        identityPoolRegion: ACCOUNT_MANAGEMENT_REGION,
        userPoolId: COGNITO_USER_POOL_ID,
        userPoolWebClientId: COGNITO_USER_POOL_WEB_CLIENT_ID,
    },
    oauth: {
        domain: COGNITO_USER_POOL_DOMAIN,
        redirectSignIn: REDIRECT_URL,
        redirectSignOut: REDIRECT_URL,
        responseType: "code",
    },
};

export const accountManagementGraphQlConfig = {
    aws_appsync_graphqlEndpoint: ACCOUNT_MANAGEMENT_APPSYNC_GRAPHQL_ENDPOINT,
    aws_appsync_region: ACCOUNT_MANAGEMENT_REGION,
    aws_appsync_authenticationType: "AWS_LAMBDA",
};

export const disclosureGraphQlConfig = {
    aws_appsync_graphqlEndpoint: APPSYNC_GRAPHQL_ENDPOINT,
    aws_appsync_region: REGION,
    aws_appsync_authenticationType: "AWS_LAMBDA",
};

export const rumConfig = {
    rumGuestRoleArn: RUM_GUEST_ROLE_ARN,
    rumIdentityPoolId: RUM_GUEST_IDENTITY_POOL_ID,
    rumEndpoint: RUM_ENDPOINT,
    rumAppId: RUM_APP_ID,
    rumAppVersion: RUM_APP_VERSION,
    rumAppRegion: REGION,
};
