import * as Types from '@amzn/gtpc-hamilton-acct-mgmt-schema';

export type RevokeTeamRoleForUserMutationVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
  teamId: Types.Scalars['ID'];
  role: Types.UserRole;
}>;


export type RevokeTeamRoleForUserMutation = { revokeTeamRoleForUser?: Types.Maybe<(
    Pick<Types.User, 'id' | 'firstName' | 'lastName'>
    & { preferences?: Types.Maybe<Pick<Types.UserPreferences, 'notificationEmailEnabled' | 'notificationEmail'>>, teams?: Types.Maybe<Array<(
      Pick<Types.TeamWithRoles, 'roles'>
      & { team: (
        Pick<Types.Team, 'id' | 'name' | 'namespace' | 'org'>
        & { partners?: Types.Maybe<Array<Types.Maybe<Pick<Types.Partner, 'id' | 'name' | 'namespace' | 'org' | 'username' | 'firstName' | 'lastName' | 'jobTitle' | 'status'>>>> }
      ) }
    )>> }
  )> };

export type UpdateTeamMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID'];
  name?: Types.InputMaybe<Types.Scalars['String']>;
  country?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateTeamMutation = { updateTeam?: Types.Maybe<Pick<Types.Team, 'id' | 'name'>> };

export type DeactivateInviteMutationVariables = Types.Exact<{
  inviteId: Types.Scalars['ID'];
}>;


export type DeactivateInviteMutation = { deactivateInvite?: Types.Maybe<Pick<Types.Invite, 'customPartnerId' | 'expiresAt' | 'firstName' | 'id' | 'inviteType' | 'jobTitle' | 'lastName' | 'partnerTeamId' | 'partnerTeamName' | 'queuedAt' | 'teamId' | 'username'>> };

export type InviteUserMutationVariables = Types.Exact<{
  firstName: Types.Scalars['String'];
  lastName: Types.Scalars['String'];
  teamId: Types.Scalars['ID'];
  username: Types.Scalars['String'];
  inviteType: Types.InviteType;
  jobTitle?: Types.InputMaybe<Types.Scalars['String']>;
  partnerTeamName?: Types.InputMaybe<Types.Scalars['String']>;
  customPartnerId?: Types.InputMaybe<Types.Scalars['String']>;
  country?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type InviteUserMutation = { inviteUser?: Types.Maybe<Pick<Types.Invite, 'id' | 'inviteType' | 'partnerTeamId' | 'partnerTeamName' | 'teamId' | 'customPartnerId' | 'country' | 'expiresAt' | 'firstName' | 'jobTitle' | 'lastName' | 'queuedAt' | 'username'>> };

export type ResendInviteMutationVariables = Types.Exact<{
  inviteId: Types.Scalars['ID'];
}>;


export type ResendInviteMutation = { resendInvite?: Types.Maybe<Pick<Types.Invite, 'id'>> };

export type UpdateUserMutationVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
  firstName?: Types.InputMaybe<Types.Scalars['String']>;
  lastName?: Types.InputMaybe<Types.Scalars['String']>;
  jobTitle?: Types.InputMaybe<Types.Scalars['String']>;
  tosAcceptedVersion?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateUserMutation = { updateUser?: Types.Maybe<Pick<Types.User, 'id' | 'firstName' | 'lastName' | 'jobTitle' | 'tosAcceptedVersion'>> };

export type AcceptPartnerInviteMutationVariables = Types.Exact<{
  inviteId: Types.Scalars['ID'];
  teamId: Types.Scalars['ID'];
}>;


export type AcceptPartnerInviteMutation = { acceptPartnerInvite?: Types.Maybe<Pick<Types.Invite, 'id'>> };

export type RejectPartnerInviteMutationVariables = Types.Exact<{
  inviteId: Types.Scalars['ID'];
}>;


export type RejectPartnerInviteMutation = { rejectPartnerInvite?: Types.Maybe<Pick<Types.Invite, 'id'>> };

export type UpdatePartnershipMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID'];
  partnerTeamId: Types.Scalars['ID'];
  customPartnerId: Types.Scalars['String'];
}>;


export type UpdatePartnershipMutation = { updatePartnership?: Types.Maybe<Pick<Types.Team, 'id'>> };

export type GetUserQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
}>;


export type GetUserQuery = { getUser?: Types.Maybe<(
    Pick<Types.User, 'id' | 'username' | 'firstName' | 'lastName' | 'jobTitle' | 'tosAcceptedVersion'>
    & { preferences?: Types.Maybe<Pick<Types.UserPreferences, 'notificationEmail' | 'notificationEmailEnabled'>>, teams?: Types.Maybe<Array<(
      Pick<Types.TeamWithRoles, 'roles'>
      & { team: (
        Pick<Types.Team, 'id' | 'name' | 'country' | 'namespace' | 'org' | 'ldapGroup' | 'permissions'>
        & { users?: Types.Maybe<Array<(
          Pick<Types.UserWithRoles, 'roles'>
          & { user?: Types.Maybe<Pick<Types.User, 'id'>> }
        )>> }
      ) }
    )>> }
  )> };

export type GetTeamMembersQueryVariables = Types.Exact<{
  teamId: Types.Scalars['ID'];
}>;


export type GetTeamMembersQuery = { getTeam?: Types.Maybe<(
    Pick<Types.Team, 'id'>
    & { users?: Types.Maybe<Array<(
      Pick<Types.UserWithRoles, 'roles'>
      & { user?: Types.Maybe<(
        Pick<Types.User, 'id' | 'username' | 'firstName' | 'lastName' | 'jobTitle'>
        & { preferences?: Types.Maybe<Pick<Types.UserPreferences, 'notificationEmail' | 'notificationEmailEnabled'>> }
      )> }
    )>> }
  )> };

export type GetTeamMemberInvitesQueryVariables = Types.Exact<{
  teamId: Types.Scalars['ID'];
}>;


export type GetTeamMemberInvitesQuery = { getTeam?: Types.Maybe<(
    Pick<Types.Team, 'id'>
    & { invitedMembers?: Types.Maybe<Array<Types.Maybe<Pick<Types.InvitedMember, 'id' | 'username' | 'firstName' | 'lastName' | 'jobTitle' | 'createdAt' | 'invitedByUserId'>>>> }
  )> };

export type GetTeamPartnersQueryVariables = Types.Exact<{
  teamId: Types.Scalars['ID'];
}>;


export type GetTeamPartnersQuery = { getTeam?: Types.Maybe<(
    Pick<Types.Team, 'id'>
    & { partners?: Types.Maybe<Array<Types.Maybe<Pick<Types.Partner, 'id' | 'name' | 'country' | 'namespace' | 'org' | 'username' | 'firstName' | 'lastName' | 'jobTitle' | 'customPartnerId' | 'status' | 'createdAt' | 'invitedByUserId' | 'invitedByUserFirstName' | 'invitedByUserLastName' | 'inviteId' | 'expiresAt' | 'queuedAt'>>>> }
  )> };

export type GetInviteQueryVariables = Types.Exact<{
  inviteId: Types.Scalars['ID'];
}>;


export type GetInviteQuery = { getInvite?: Types.Maybe<Pick<Types.Invite, 'username' | 'teamId' | 'teamName' | 'queuedAt' | 'partnerTeamName' | 'partnerTeamId' | 'lastName' | 'jobTitle' | 'inviteType' | 'id' | 'firstName' | 'expiresAt' | 'customPartnerId' | 'country'>> };


export const RevokeTeamRoleForUserDocument = `
    mutation revokeTeamRoleForUser($userId: ID!, $teamId: ID!, $role: UserRole!) {
  revokeTeamRoleForUser(userId: $userId, teamId: $teamId, role: $role) {
    id
    firstName
    lastName
    preferences {
      notificationEmailEnabled
      notificationEmail
    }
    teams {
      roles
      team {
        id
        name
        namespace
        org
        partners {
          id
          name
          namespace
          org
          username
          firstName
          lastName
          jobTitle
          status
        }
      }
    }
  }
}
    `;
export const UpdateTeamDocument = `
    mutation updateTeam($teamId: ID!, $name: String, $country: String) {
  updateTeam(request: {teamId: $teamId, name: $name, country: $country}) {
    id
    name
  }
}
    `;
export const DeactivateInviteDocument = `
    mutation deactivateInvite($inviteId: ID!) {
  deactivateInvite(inviteId: $inviteId) {
    customPartnerId
    expiresAt
    firstName
    id
    inviteType
    jobTitle
    lastName
    partnerTeamId
    partnerTeamName
    queuedAt
    teamId
    username
  }
}
    `;
export const InviteUserDocument = `
    mutation inviteUser($firstName: String!, $lastName: String!, $teamId: ID!, $username: String!, $inviteType: InviteType!, $jobTitle: String, $partnerTeamName: String, $customPartnerId: String, $country: String) {
  inviteUser(
    request: {firstName: $firstName, lastName: $lastName, teamId: $teamId, username: $username, inviteType: $inviteType, jobTitle: $jobTitle, partnerTeamName: $partnerTeamName, customPartnerId: $customPartnerId, country: $country}
  ) {
    id
    inviteType
    partnerTeamId
    partnerTeamName
    teamId
    customPartnerId
    country
    expiresAt
    firstName
    jobTitle
    lastName
    queuedAt
    username
  }
}
    `;
export const ResendInviteDocument = `
    mutation resendInvite($inviteId: ID!) {
  resendInvite(inviteId: $inviteId) {
    id
  }
}
    `;
export const UpdateUserDocument = `
    mutation updateUser($userId: ID!, $firstName: String, $lastName: String, $jobTitle: String, $tosAcceptedVersion: String) {
  updateUser(
    request: {userId: $userId, firstName: $firstName, lastName: $lastName, jobTitle: $jobTitle, tosAcceptedVersion: $tosAcceptedVersion}
  ) {
    id
    firstName
    lastName
    jobTitle
    tosAcceptedVersion
  }
}
    `;
export const AcceptPartnerInviteDocument = `
    mutation acceptPartnerInvite($inviteId: ID!, $teamId: ID!) {
  acceptPartnerInvite(inviteId: $inviteId, teamId: $teamId) {
    id
  }
}
    `;
export const RejectPartnerInviteDocument = `
    mutation rejectPartnerInvite($inviteId: ID!) {
  rejectPartnerInvite(inviteId: $inviteId) {
    id
  }
}
    `;
export const UpdatePartnershipDocument = `
    mutation updatePartnership($teamId: ID!, $partnerTeamId: ID!, $customPartnerId: String!) {
  updatePartnership(
    request: {teamId: $teamId, partnerTeamId: $partnerTeamId, customPartnerId: $customPartnerId}
  ) {
    id
  }
}
    `;
export const GetUserDocument = `
    query getUser($userId: ID!) {
  getUser(userId: $userId) {
    id
    username
    firstName
    lastName
    jobTitle
    tosAcceptedVersion
    preferences {
      notificationEmail
      notificationEmailEnabled
    }
    teams {
      roles
      team {
        id
        name
        country
        namespace
        org
        ldapGroup
        permissions
        users {
          roles
          user {
            id
          }
        }
      }
    }
  }
}
    `;
export const GetTeamMembersDocument = `
    query getTeamMembers($teamId: ID!) {
  getTeam(teamId: $teamId) {
    id
    users {
      user {
        id
        username
        firstName
        lastName
        jobTitle
        preferences {
          notificationEmail
          notificationEmailEnabled
        }
      }
      roles
    }
  }
}
    `;
export const GetTeamMemberInvitesDocument = `
    query getTeamMemberInvites($teamId: ID!) {
  getTeam(teamId: $teamId) {
    id
    invitedMembers {
      id
      username
      firstName
      lastName
      jobTitle
      createdAt
      invitedByUserId
    }
  }
}
    `;
export const GetTeamPartnersDocument = `
    query getTeamPartners($teamId: ID!) {
  getTeam(teamId: $teamId) {
    id
    partners {
      id
      name
      country
      namespace
      org
      username
      firstName
      lastName
      jobTitle
      customPartnerId
      status
      createdAt
      invitedByUserId
      invitedByUserFirstName
      invitedByUserLastName
      inviteId
      expiresAt
      queuedAt
    }
  }
}
    `;
export const GetInviteDocument = `
    query getInvite($inviteId: ID!) {
  getInvite(inviteId: $inviteId) {
    username
    teamId
    teamName
    queuedAt
    partnerTeamName
    partnerTeamId
    lastName
    jobTitle
    inviteType
    id
    firstName
    expiresAt
    customPartnerId
    country
  }
}
    `;
export type Requester<C = {}, E = unknown> = <R, V>(doc: string, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C, E>(requester: Requester<C, E>) {
  return {
    revokeTeamRoleForUser(variables: RevokeTeamRoleForUserMutationVariables, options?: C): Promise<RevokeTeamRoleForUserMutation> {
      return requester<RevokeTeamRoleForUserMutation, RevokeTeamRoleForUserMutationVariables>(RevokeTeamRoleForUserDocument, variables, options) as Promise<RevokeTeamRoleForUserMutation>;
    },
    updateTeam(variables: UpdateTeamMutationVariables, options?: C): Promise<UpdateTeamMutation> {
      return requester<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument, variables, options) as Promise<UpdateTeamMutation>;
    },
    deactivateInvite(variables: DeactivateInviteMutationVariables, options?: C): Promise<DeactivateInviteMutation> {
      return requester<DeactivateInviteMutation, DeactivateInviteMutationVariables>(DeactivateInviteDocument, variables, options) as Promise<DeactivateInviteMutation>;
    },
    inviteUser(variables: InviteUserMutationVariables, options?: C): Promise<InviteUserMutation> {
      return requester<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, variables, options) as Promise<InviteUserMutation>;
    },
    resendInvite(variables: ResendInviteMutationVariables, options?: C): Promise<ResendInviteMutation> {
      return requester<ResendInviteMutation, ResendInviteMutationVariables>(ResendInviteDocument, variables, options) as Promise<ResendInviteMutation>;
    },
    updateUser(variables: UpdateUserMutationVariables, options?: C): Promise<UpdateUserMutation> {
      return requester<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables, options) as Promise<UpdateUserMutation>;
    },
    acceptPartnerInvite(variables: AcceptPartnerInviteMutationVariables, options?: C): Promise<AcceptPartnerInviteMutation> {
      return requester<AcceptPartnerInviteMutation, AcceptPartnerInviteMutationVariables>(AcceptPartnerInviteDocument, variables, options) as Promise<AcceptPartnerInviteMutation>;
    },
    rejectPartnerInvite(variables: RejectPartnerInviteMutationVariables, options?: C): Promise<RejectPartnerInviteMutation> {
      return requester<RejectPartnerInviteMutation, RejectPartnerInviteMutationVariables>(RejectPartnerInviteDocument, variables, options) as Promise<RejectPartnerInviteMutation>;
    },
    updatePartnership(variables: UpdatePartnershipMutationVariables, options?: C): Promise<UpdatePartnershipMutation> {
      return requester<UpdatePartnershipMutation, UpdatePartnershipMutationVariables>(UpdatePartnershipDocument, variables, options) as Promise<UpdatePartnershipMutation>;
    },
    getUser(variables: GetUserQueryVariables, options?: C): Promise<GetUserQuery> {
      return requester<GetUserQuery, GetUserQueryVariables>(GetUserDocument, variables, options) as Promise<GetUserQuery>;
    },
    getTeamMembers(variables: GetTeamMembersQueryVariables, options?: C): Promise<GetTeamMembersQuery> {
      return requester<GetTeamMembersQuery, GetTeamMembersQueryVariables>(GetTeamMembersDocument, variables, options) as Promise<GetTeamMembersQuery>;
    },
    getTeamMemberInvites(variables: GetTeamMemberInvitesQueryVariables, options?: C): Promise<GetTeamMemberInvitesQuery> {
      return requester<GetTeamMemberInvitesQuery, GetTeamMemberInvitesQueryVariables>(GetTeamMemberInvitesDocument, variables, options) as Promise<GetTeamMemberInvitesQuery>;
    },
    getTeamPartners(variables: GetTeamPartnersQueryVariables, options?: C): Promise<GetTeamPartnersQuery> {
      return requester<GetTeamPartnersQuery, GetTeamPartnersQueryVariables>(GetTeamPartnersDocument, variables, options) as Promise<GetTeamPartnersQuery>;
    },
    getInvite(variables: GetInviteQueryVariables, options?: C): Promise<GetInviteQuery> {
      return requester<GetInviteQuery, GetInviteQueryVariables>(GetInviteDocument, variables, options) as Promise<GetInviteQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;