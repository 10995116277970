/**
 * a tiny helper for managing local storage
 */

export const ACTIVE_TEAM_ID_KEY = "activeTeam";
export const SANDBOX_ACTIVE_KEY = "sandboxActive";

let activeTeamId = localStorage.getItem(ACTIVE_TEAM_ID_KEY);
let sandboxActive = localStorage.getItem(SANDBOX_ACTIVE_KEY) === "true";

export const isSandboxActive = (): boolean => {
    return sandboxActive;
};

export const getActiveTeamId = (): string | undefined => {
    return activeTeamId ?? undefined;
};

export const saveSandboxActive = (isActive: boolean): void => {
    sandboxActive = isActive;
    localStorage.setItem(SANDBOX_ACTIVE_KEY, String(isActive));
};

export const saveActiveTeamId = (id: string): void => {
    activeTeamId = id;
    localStorage.setItem(ACTIVE_TEAM_ID_KEY, id);
};

export const clearActiveTeamid = (): void => {
    activeTeamId = null;
    localStorage.removeItem(ACTIVE_TEAM_ID_KEY);
};
