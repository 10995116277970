interface Log {
    // eslint-disable-next-line @typescript-eslint/prefer-function-type
    (message?: unknown, ...optionalParams: unknown[]): void;
}

export interface Logger {
    info?: Log;
    debug?: Log;
    warn?: Log;
    error?: Log;
}

const loggers = new Map<string, Logger>();

const addLogger = (id: string, logger: Logger): void => {
    loggers.set(id, logger);
};

export default {
    debug: (message?: unknown, ...optionalParams: unknown[]): void => {
        loggers.forEach(logger => logger.debug && logger.debug(message, ...optionalParams));
    },
    info: (message?: unknown, ...optionalParams: unknown[]): void => {
        loggers.forEach(logger => logger.info && logger.info(message, ...optionalParams));
    },
    warn: (message?: unknown, ...optionalParams: unknown[]): void => {
        loggers.forEach(logger => logger.warn && logger.warn(message, ...optionalParams));
    },
    error: (message?: unknown, ...optionalParams: unknown[]): void => {
        loggers.forEach(logger => logger.error && logger.error(message, ...optionalParams));
    },
    addLogger,
};
