/**
 * pre-loads our graphql calls
 *
 * @note try to keep this file as small as possible with minimal dependencies
 */

import { Amplify, Auth } from "aws-amplify";
import { OperationResult } from "urql";

import { cognitoUserConfig, accountManagementGraphQlConfig } from "../aws-exports";
import { DATA_REQUEST_PATH } from "../routes/data-requests/constants";

import {
    ListRequesterDataRequestsDocument,
    ListProviderDataRequestsDocument,
    GetFeatureFlagDocument,
} from "./clients/disclosure/disclosure-sdk";
import { GetUserDocument, GetUserQuery } from "./clients/ham/ham-sdk";
import { TypeNames, getGraphQLClient, UrqlContext } from "./clients/urql";
import { getActiveTeamId } from "./storage";

// get user still lives in redux for now because it needs to persist across multiple teams
// and our urql cache is currently tied to a specific team
let userPromise: Promise<OperationResult<GetUserQuery>> | null = null;
export const getUserPromise = (): typeof userPromise => userPromise;

export function startPreloadingFeatureFlags(featureNameToEntity: { [key: string]: string }, teamId?: string): void {
    if (teamId && Object.keys(featureNameToEntity).length >= 1) {
        const graphQLClient = getGraphQLClient(teamId);
        for (const feature in featureNameToEntity) {
            const entityId = featureNameToEntity[feature];
            void graphQLClient.query(GetFeatureFlagDocument, { feature, entityId }).toPromise();
        }
    }
}

export async function prefetch(): Promise<void> {
    // setup the amplify config (required for authentication)
    Amplify.configure(cognitoUserConfig);

    // try to establish a valid user session
    const userId = await Auth.currentSession()
        .then(session => session.getIdToken())
        .then(({ payload }) => payload.sub)
        .catch(() => {});

    // if we're on the log out page or something don't bother prefetching
    if (!userId) {
        return;
    }

    // grab the team id and establish the graphql client
    const teamId = getActiveTeamId();
    const graphQLClient = getGraphQLClient(teamId);

    // load the feature flags
    startPreloadingFeatureFlags({ bulkDrs: userId }, teamId);

    // the getUser promise is cached so we can access the value even if we change teams
    const queryContext: UrqlContext = { url: accountManagementGraphQlConfig.aws_appsync_graphqlEndpoint };
    userPromise = graphQLClient.query(GetUserDocument, { userId }, queryContext).toPromise();

    // get data requsts if you already are logged in, have an active team and are on the right page
    const isValidPath = ["/", DATA_REQUEST_PATH].includes(location.pathname);
    if (teamId && isValidPath) {
        const mutationContext: UrqlContext = { additionalTypenames: [TypeNames.DataRequest] };
        void graphQLClient.query(ListRequesterDataRequestsDocument, {}, mutationContext).toPromise();
        void graphQLClient.query(ListProviderDataRequestsDocument, {}, mutationContext).toPromise();
    }
}
