import { makeOperation, errorExchange } from "@urql/core";
import { authExchange } from "@urql/exchange-auth";
import { Auth } from "aws-amplify";
import { Client, Operation, createClient, defaultExchanges } from "urql";

import { disclosureGraphQlConfig } from "../../aws-exports";
import logger from "../logger";
import { getActiveTeamId } from "../storage";

// See: https://formidable.com/open-source/urql/docs/basics/document-caching/#adding-typenames
export const TypeNames = {
    DataRequest: "DataRequest",
} as const;

// the type that urql provides is too leanient, so we created this
export interface UrqlContext {
    url?: string;
    pause?: boolean;
    additionalTypenames?: (keyof typeof TypeNames)[];
}

type AuthState = { token: string; expireAt: number } | null;
interface AddAuthProps {
    operation: Operation;
    authState: AuthState;
}

const addAuthToOperation = ({ authState, operation }: AddAuthProps): ReturnType<typeof makeOperation> => {
    if (!authState || !authState.token) {
        return operation;
    }
    return makeOperation(operation.kind, operation, {
        ...operation.context,
        fetchOptions: {
            headers: {
                "active-team-id": getActiveTeamId() || "",
                Authorization: authState.token,
            },
        },
    });
};

// sets up initial authentication for urql
// see: https://formidable.com/open-source/urql/docs/advanced/authentication/
const getAuth = async (): Promise<AuthState> => {
    try {
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken();
        const token = accessToken.getJwtToken();
        const expireAt = accessToken.getExpiration();
        return { token, expireAt };
    } catch (e) {
        logger.warn("Could not establish session", e);
    }
    return null;
};

const TOKEN_LIFETIME_THRESHOLD_SEC = 10;

const willAuthError = ({ authState }: { authState: AuthState }): boolean => {
    if (authState === null) {
        return true;
    }

    const now = Date.now() / 1000;
    // we want to try to refresh token a bit earlier as it is up to aws-amplify library
    // to decide when the token should be refreshed exactly
    // so if we force to start calling getAuth function before token expiry date
    // eventually it will be refreshed by the library and we'll get the new token with its new expiry date
    return authState.expireAt - now < TOKEN_LIFETIME_THRESHOLD_SEC;
};

// cache clients by team id
const clients: Record<string, Client> = {};
export const getGraphQLClient = (teamId = "default"): Client => {
    if (!clients[teamId]) {
        clients[teamId] = createClient({
            url: disclosureGraphQlConfig.aws_appsync_graphqlEndpoint,
            exchanges: [
                authExchange({
                    willAuthError,
                    getAuth,
                    addAuthToOperation,
                }),
                errorExchange({
                    onError(error, operation) {
                        logger.error("GraphQL request failed:", { error, operation });
                    },
                }),
                ...defaultExchanges,
            ],
        });
    }
    return clients[teamId];
};
